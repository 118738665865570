@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto Condensed';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: text;

  @apply bg-blue;
  @apply text-white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @apply text-h1 font-roboto-condensed font-bold text-petrolblue text-4xl;
}

h2 {
  @apply text-h2 font-roboto-condensed font-normal text-darkgray text-2xl;
}

h3 {
  @apply text-h3 font-roboto font-bold text-applegreen text-xl;
}

p {
  @apply text-p font-roboto font-normal text-base text-lightgray;
}

@layer base {
  h1 {
    @apply text-h1 font-roboto-condensed font-bold text-petrolblue text-4xl leading-[1.3];
  }

  h2 {
    @apply text-h2 font-roboto-condensed font-normal text-darkgray text-2xl leading-[1.3];
  }

  h3 {
    @apply text-h3 font-roboto font-bold text-applegreen text-xl leading-[1.3];
  }

  p {
    @apply text-p font-roboto font-normal text-base text-lightgray leading-relaxed;
  }

  em,
  i {
    @apply font-roboto italic;
  }
}

a:hover,
button:hover {
  text-decoration: underline;
}

/* Hide arrows number fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}